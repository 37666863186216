@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
@import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
@import 'ag-grid-community/dist/styles/ag-theme-balham.css';
@import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

@import 'ag-grid-community/src/styles/ag-grid.scss';
@import 'ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';
@import 'ag-grid-community/src/styles/ag-theme-alpine-dark/sass/ag-theme-alpine-dark-mixin';
@import 'ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham-mixin';
@import 'ag-grid-community/src/styles/ag-theme-balham-dark/sass/ag-theme-balham-dark-mixin';

/*
    AG Table styles start
*/

.table-wrapper-full-page-height ag-grid-angular {
    height: calc(100vh - 106px);
}

.table-wrapper-only-table ag-grid-angular {
    height: calc(100vh - 69px);
}

.ag-with-total ag-grid-angular {
    height: calc(100vh - 221px);
}


@media screen and (min-width: 1092px) {
    .ag-with-total ag-grid-angular {
        height: calc(100vh - 215px);
    }
}

.table-wrapper-full-page-height {
    p-splitter {
        ag-grid-angular {
            height: 100%;
        }
    }
}

.ag-total .ag-cell-value {
    font-weight: bold;
}

.ag-cell-value {
    padding: 0 !important;
}

.ag-row.detail-row-max-height {
    .ag-details-row.ag-details-row-auto-height {
        max-height: 330px;

        .ag-root.ag-layout-auto-height {
            max-height: 300px;

            .ag-body-viewport.ag-layout-auto-height {
                overflow-y: auto;
            }
        }
    }
}

.detail-value-success app-date-cell-renderer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.detail-value-success app-date-cell-renderer .two-line-cell-renderer {
    color: rgb(63, 63, 63);
    background-color: rgb(121, 242, 184);
    border-radius: 8px;
}

.two-line-cell-renderer {
    font-size: 0.928rem;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    line-height: min(20px);
}

.two-line-cell-renderer-top {
    height: 18px;
}

.two-line-cell-renderer-bottom {
    height: 18px;
}

.with-borders {

    .ag-theme-alpine {
        .ag-ltr .ag-cell {
            border-left-color: #dde2eb;
        }

        .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
            border-left-color: #dde2eb;
        }
    }

    .ag-theme-alpine-dark {
        .ag-ltr .ag-cell {
            border-left-color: #304562;
        }

        .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
            border-left-color: #304562;
        }
    }

}

.ag-root-wrapper .ag-body-viewport .ag-details-row {
    padding: 12px;
}

.ag-theme-alpine {
    .ag-ltr .ag-cell.ag-cell-bordered {
        border-left-color: #dde2eb;
        border-right-color: #dde2eb;
        padding: 0 !important;

        .two-line-cell-renderer-align {
            border-bottom: 1px solid #dde2eb;
        }
    }
}

.ag-theme-alpine-dark {
    .ag-ltr .ag-cell.ag-cell-bordered {
        border-left-color: #304562;
        border-right-color: #304562;
        padding: 0 !important;

        .two-line-cell-renderer-align {
            border-bottom: 1px solid #304562;
        }
    }
}

.ag-theme-alpine-dark {
    --ag-selected-row-background-color: #304562;
    --ag-row-hover-color: #304562;

    @include ag-theme-alpine-dark((
        // use theme parameters where possible
        font-family: $fontFamily,
        background-color: $topbarBg,
        odd-row-background-color: #17212F,
        header-background-color: $bodyBg,
        border-color: $dividerColor,
        secondary-border-color: $dividerColor,
        control-panel-background-color: $bodyBg,
        subheader-background-color: $bodyBg,
        range-selection-border-color: #629aeb33,
        header-height: 40px
    ));

    input[class^=ag-][type=text] {
        border-color: #1B2637;
        background: #1F2D40;
    }

    .ag-floating-filter-button-button {
        color: #89929F;
    }

    .ag-header-cell-text,
    .ag-row {
        color: $tableTextColor;
    }

    .ag-row-group-expanded {
        background: #304562;
    }

    .ag-row-hover {
        background: rgba(48, 69, 98, 0.75);
    }

    .ag-row-focus {
        background: var(--ag-row-hover-color, rgba(33, 150, 243, 0.1));
    }

    .ag-row-hover.ag-row-selected::before {
        background-color: var(--ag-row-hover-color, rgba(33, 150, 243, 0.1));
        background-image: none;
    }

    .ag-icon-filter {
        cursor: pointer;
    }
}

.view-notes-dialog .ag-theme-alpine-dark {
    @include ag-theme-alpine-dark((
        // use theme parameters where possible
        background-color: $bodyBg,
        odd-row-background-color: $bodyBg,
        header-background-color: $bodyBg,
        border-color: $bodyBg,
        secondary-border-color: $bodyBg,
        control-panel-background-color: $bodyBg,
        subheader-background-color: $bodyBg
    ));

    .ag-row-hover {
        background: transparent;
    }

    .ag-group-expanded {
        background-color: $bodyBg;
    }
}

.ag-theme-alpine {
    --ag-selected-row-background-color: #EFF8FF;
    --ag-row-hover-color: #EFF8FF;

    @include ag-theme-alpine((
        font-family: $fontFamily,
        background-color: #FFFFFF,
        odd-row-background-color: #FCFCFD,
        header-background-color: #FCFCFD,
        border-color: #E4E5F1,
        range-selection-border-color: #e4e4e4,
        header-height: 40px
    ));

    .ag-header-cell-text,
    .ag-row {
        color: #343A40;
    }

    .ag-group-contracted,
    .ag-group-expanded {
        color: #CBCBD8;
    }

    .ag-row-group-expanded {
        background: #EFF8FF;
    }

    .ag-row-hover {
        background: rgba(242, 242, 242, 0.75);
    }

    .ag-row-focus {
        background:  var(--ag-row-hover-color, rgba(33, 150, 243, 0.1));
    }

    .ag-row-hover.ag-row-selected::before {
        background-color: var(--ag-row-hover-color, rgba(33, 150, 243, 0.1));
        background-image: none;
    }

    .ag-icon-filter {
        cursor: pointer;
    }

    .ag-cell-value .ag-row-group-leaf-indent {
        margin: 0;
    }

    .ag-menu-option {
        color: #54545F;

        .ag-menu-option-icon, .ag-menu-option-popup-pointer {
            color: #B3B3BC;
        }
    }
}

.ag-header-cell-text {
    font-weight: 900;
    font-size: 14px;
}

.ag-header-group-cell {
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.ag-header-cell {
    font-weight: 600;
    font-size: 0.928rem;
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.ag-paging-panel {
    height: 32px !important;
}

.ag-cell {
    font-weight: 400;
    font-size: 0.928rem;
    padding-left: 4px !important;
    padding-right: 4px !important;

    > span {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .ag-checkbox-input {
        cursor: pointer;
    }
}

.ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
    border-color: #5b96c2;
}

.ag-theme-alpine-dark .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
    border-color: #85a9c2;
}

.ag-layout-auto-height .ag-center-cols-clipper,
.ag-layout-auto-height .ag-center-cols-container,
.ag-layout-print .ag-center-cols-clipper,
.ag-layout-print .ag-center-cols-container {
    min-height: 80px !important;
}

.radiused .ag-root-wrapper {
    border-radius: 5px;
}

.icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%
}

.ag-overlay {
    top: 40px
}

.table-without-top-items {
    margin-top: 35px;
}

/*
    AG Table styles end
*/
