$gutter: 1rem; //for primeflex grid system

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

@import "assets/sass/general/general.scss";
@import 'assets/sass/variables/layout/layout_dim';
@import 'assets/sass/variables/layout/layout_light';
@import 'assets/sass/ag-table/ag_table';

* {
    touch-action: manipulation;
    user-select: text;
}

body {

    &.layout-rtl {
        direction: rtl;
    }
}

.custom-table-icon {
    font-size: 10px;
    margin-right: 5px;
}

p-toast, .p-toast, p-toastitem {
    touch-action: none !important;

    * {
        touch-action: none !important;
    }

    .p-toast-icon-close {
        touch-action: auto;
    }
}


.grid {
    margin-right: -6px;
    margin-left: -6px;
    margin-top: -6px;
}

/*
    Client's note styles start
 */
.name-surname {
    margin-left: 4px;
    color: var(--primary-color);;
}

.client-notes-icon {
    display: inline-flex;
    vertical-align: middle;
    font-size: 24px;
    cursor: pointer;

    &.view-player-notes {
        color: var(--primary-color);;
    }
}

/*
    Client's note styles end
 */

/*
    Dialog styles end
 */
.p-dialog {
    .p-dialog-footer {
        width: 100%;
        height: 56px;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        border-top: 0 none;
        padding: 10px;
        box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.05);

        button {
            margin-top: 0;
            margin-bottom: 0;
            margin-inline-start: 0;
            margin-inline-end: 0.5rem;
            width: auto;
        }
    }

    .grid {
        margin-right: 0;
        margin-left: 0;
        margin-top: 0;
    }
}

.dialog-dropdown .p-dropdown-items-wrapper {
    max-height: 110px !important;
}

@media screen and (max-width: 991px) {
    .p-dialog {
        width: 90% !important;
    }
}

/*
    Dialog styles end
 */


/*
    Calendar styles end
 */
@media only screen and (max-width: 769px) {
    p-calendar {
        left: 0 !important;
    }

    .p-datepicker td > span {
        margin: 3px auto !important;
    }
}

/*
    Calendar styles end
 */

/*
    Global styles start
 */
.link-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.link {
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 2px;
    // status color
    background: red;
}

.p-toolbar {
    background: transparent;
    border: none;
    padding: 0;
    margin-bottom: 8px;
}

.no-data-found {
    width: 100%;
    height: 100%;
    font-weight: 600;
    font-size: 17.5px;
    line-height: 24px;

    i {
        font-size: 22px;
    }
}

/*
    Global styles end
 */

/*
    Notes' styles start
 */
app-ggr-component,
app-notes-component,
app-comment-component,
app-notes-edit-component {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

app-view-notes {
    background: inherit;
}

.add-note-dialog,
.history-view-dialog,
.view-notes-dialog,
.add-edit-dialog {
    height: 515px;
}

.add-note-dialog, add-edit-dialog {
    padding-bottom: 32px;
    overflow: hidden;
}

.view-notes-dialog {
    .p-dialog-header-icons {
        z-index: 999;
    }
}

/*
    Notes' styles end
 */

/*
    Client's note styles start
 */
.name-surname {
    margin-left: 4px;
    color: var(--primary-color);;
}

.client-notes-icon {
    display: inline-flex;
    vertical-align: middle;
    font-size: 24px;
    cursor: pointer;

    &.view-player-notes {
        color: var(--primary-color);;
    }
}

/*
    Client's note styles end
 */


.comment-button {
    min-width: 144px;
    max-width: 144px;
    justify-content: flex-start;
}

/*
    Info pages' styles start
 */
.main-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3.5px 20px;

    .title {
        font-weight: 600;
        font-size: 14px;
        white-space: nowrap;
        padding: 0.5rem 0;
    }

    .action-buttons {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        justify-content: space-between;

        .action-block {
            display: flex;
            align-items: center;
        }

        .icon-notes, .icon-password, .icon-message, .icon-edit {
            padding-inline-end: 0;
            margin-inline-end: 0;
        }
    }
}

.detail {

    &-inner {
        height: auto;
    }

    &-key {
        line-height: 15px;
        margin-bottom: 0;
    }

    &-value {
        line-height: 18px;

        &-green {
            color: #79F2B8;
        }

        &-red {
            color: #EE6464;
        }

        &-bg-green {
            background: rgb(121, 242, 184);
            color: #3F3F3F;
        }

        &-bg-red {
            background: rgb(255, 214, 218);
            color: #3F3F3F;
        }
    }
}

.inner-page-info-item,
.detail-inner-wrapper {
    .detail-inner {
        &:last-child {
            border-bottom: none;
        }
    }
}

.inner-page-info {
    &-top-section {
        margin-top: -15px;
        padding: 0 20px 14px;

        i {
            margin-inline-end: 13.5px;
        }

        &-label {
            font-weight: 600;
            font-size: 11px;
        }
    }

    &-bottom-section {
        margin-left: -6px;
        margin-right: -6px;
        display: none;

        .detail {
            padding-top: 10px;
            padding-bottom: 10px;

            &-inner {
                padding: 10px 0;
            }

            &-key {
                margin-bottom: 5px;
            }
        }
    }

    &-right-section {
        border-radius: 12px;
        margin-top: 15px;
        padding: 4px 12px;

        > div {
            padding: 11px 0 5px 0;

            &:not(:last-child) {
                border-bottom: 1px solid rgba(228, 229, 241, 0.4);
            }
        }

        .detail-key {
            margin-bottom: 0;
        }

        p-progressbar {
            width: 50%;
        }
    }
}

.top-section-item {
    flex: 0 0 10%;
    min-width: 140px;
    max-width: 190px;
    display: flex;
    align-items: center;

    .detail-inner {
        width: 80%;
    }
}

.tabs-wrapper {
    margin-top: 6px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    .p-tabmenu .p-tabmenu-nav {
        border-radius: 8px;

        &-container {
            border-radius: 8px 8px 0 0;
            // TODO: remove when find out how to make tabmenu change direction
            direction: ltr
        }

        .p-menuitem-text {
            white-space: nowrap;
        }

        &-next {
            box-shadow: -2px 0 5px rgb(0 0 0 / 8%);
            border-radius: 0 8px 0 0;
        }

        &-prev {
            box-shadow: 2px 0 5px rgb(0 0 0 / 8%);
            border-radius: 8px 0 0 0;
        }

        &-btn {
            width: 22px;
            height: 43px;
            touch-action: manipulation;

            span {
                font-size: 9px;
            }
        }

        .p-tabmenuitem {
            &.p-highlight {
                .p-menuitem-link {
                    font-weight: 600;
                    font-size: 14px;
                }
            }

            .p-menuitem-link {
                font-weight: 400;
                font-size: 14px;
                padding: 1rem 20px;
                border-width: 0 0 2px 0;

                &:not(.p-disabled):focus {
                    box-shadow: none;
                }
            }
        }
    }

    .table-wrapper {
        padding: 8px;

        &.section {
            padding: 15px 14px;
        }
    }
}

@media screen and (min-width: 1091px) {
    .top-section-item {
        margin-inline-end: 15px;
    }

    .inner-page-info {
        &-bottom-section {
            display: flex;
        }

        &-right-section {
            margin-inline-end: 16px;
        }
    }

    .more-less-wrapper {
        display: none;
    }
}

@media screen and (max-width: 1090px) {
    .inner-page-info {
        &-top-section {
            padding: 0 19px;
            margin-left: -6px;
            margin-right: -6px;

            > div {
                justify-content: space-between;
            }
        }

        &-right-section {
            border: none;
            margin-top: 0;
            padding-top: 0;
            padding-bottom: 0;

            > div {
                padding-top: 5px;
            }
        }
    }

    .top-section-item {
        flex: 0 0 49%;
        min-width: 49%;
        max-width: 49%;

        &:nth-last-of-type(-n+2) {
            border-bottom: none;
        }
    }
}

.status-badge {
    cursor: default;
    height: 24px;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 600;
    padding: 4.5px 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

app-transaction-statuses-renderer {
    display: flex;
    align-items: center;
    height: 100%;
}

/*
    Info pages' styles end
 */


/*
    Up and down buttons section's styles start
 */

.up-down-buttons-container {
    width: 34px;
    height: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: none;

    .arrow {
        height: 17px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}

/*
   Up and down buttons section's styles end
 */

/*
    Icons sizes styles start
 */

.icon-size-xs {
    font-size: 16px;
}

.icon-size-sm {
    font-size: 18px;
}

.icon-size-md {
    font-size: 24px;
}

.icon-size-lg {
    font-size: 28px;
}

.icon-size-xl {
    font-size: 36px;
}

/*
   Icons sizes styles end
 */

/*
   Commission styles start
 */

.spinner-container {
    display: flex;
    justify-content: center;

    .pi-spinner {
        font-size: 80px;
        padding: 60px;
    }
}

.section {

    .title {
        font-weight: 600;
        font-size: 18px;
        margin-top: 20px;

        &.commission-plan {
            padding: 8px 14px 0;
        }
    }

    .add-btn {
        width: unset;
    }

    &.add-commission-plan-section {
        padding-bottom: 30px;
    }
}


/*
   Commission styles end
 */


/*
   Tooltip styles start
 */


.p-tooltip {
    .p-tooltip-arrow {
        margin-left: 1px;
    }

    &.retry-tooltip {
        &.p-tooltip-left {
            .p-tooltip-arrow {
                margin-right: 1px;
            }
        }
    }
}

/*
   Tooltip styles end
 */


/*
    Direction styles start
 */

.layout-ltr {
    direction: ltr;
}

/*
    Direction styles end
 */
